import { getLocaleStorage, isBrowser, useLocalStorage } from '@lib/browser';
import { AUTH_KEY, AUTH_TOKEN_NAME, ROUTER_LINKS } from '@/shared/config';
import { getMyCars, getViewer } from './viewer.api';
import { GetAllUserCarsDto, Viewer, VIEWER_ROLE } from './viewer.types';
import { DataWithNavigation } from '@/shared/api';
import { ClassifiedCar } from '../sell-car';

const useViewer = () => useLocalStorage<boolean>(AUTH_KEY, false);

export const useAuth = () => {
  const links = {
    login: ROUTER_LINKS.goLogin(),
    logout: ROUTER_LINKS.logout,
  };

  return {
    links,
    tokenName: AUTH_TOKEN_NAME,
    methods: {
      login() {
        window.open(ROUTER_LINKS.goLogin(), '_self');
      },
      logout() {
        useViewer().remove();
        window.open(links.logout, '_self');
      },
    },
  };
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  return getLocaleStorage<boolean>(AUTH_KEY, false);
};

export const redirectToHomePageForUnauthorizedUsers = () => {
  if (!isBrowser) return false;

  const isAuth = isLoggedIn();

  if (!isAuth) {
    getViewer().then((viewer) => {
      if (viewer) {
        useViewer().set(true);
        window.location.reload();
      } else {
        window.open(ROUTER_LINKS.goLogin(), '_self');
      }
    });
  }

  return isAuth;
};
export const isAdmin = (viewer: Viewer | null): boolean => {
  return !!viewer && viewer.role === VIEWER_ROLE.ADMIN;
};

export const viewerQueries = (viewer: Viewer) => {
  return {
    getCars: async (
      props?: Partial<GetAllUserCarsDto>
    ): Promise<DataWithNavigation<'cars', ClassifiedCar[]>> => {
      const defaultResponse: DataWithNavigation<'cars', ClassifiedCar[]> = {
        page: 1,
        pageCount: 0,
        cars: [],
      };
      const { userId, ...pageProps } = props || {};
      let viewerId: string | undefined = viewer ? viewer.id : userId;

      if (!viewerId) {
        const viewer = await getViewer();

        if (viewer) {
          viewerId = viewer.id;
        } else {
          return defaultResponse;
        }
      }

      return getMyCars({ userId: viewerId, ...pageProps });
    },
  };
};
