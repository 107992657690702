import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews';
import { linkResolver } from './src/utils/linkResolver';

// Fonts
import '@fontsource/roboto-condensed/latin.css';
import '@fontsource/cinzel/latin.css';

// CSS
import './src/styles/default.css';
import { ViewerProvider } from './src/entities/viewer';

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => (
  <ViewerProvider>
    <PrismicProvider
      linkResolver={linkResolver}
      externalLinkComponent={({ href, className, ...props }) => {
        if (href.includes(process.env.GATSBY_COMPANY_URL)) {
          return (
            <a
              data-location="internal"
              href={href}
              target="_self"
              className={className}
            >
              {props.children}
            </a>
          );
        }
        return (
          <a
            data-location="external"
            href={href}
            className={className}
            {...props}
          >
            {props.children}
          </a>
        );
      }}
      internalLinkComponent={({ href, ...props }) => {
        if (href.includes('auction')) {
          return (
            <a data-location="auction" href={href} {...props}>
              {props.children}
            </a>
          );
        }
        return <Link to={href} {...props} />;
      }}
    >
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </PrismicProvider>
  </ViewerProvider>
);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <ToastContainer />
    </>
  );
};
