export const TOKEN_KEY = 'bidJSTokentocca';
export const AUTH_KEY = 'viewer';
export const API_URL = process.env.GATSBY_API_URL;
export const AUTH_TOKEN_NAME = 'connect.sid';
export const PLACEHOLDER_SRC = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;
export const IS_STAGE = process.env.IS_STAGE === 'true';
export const DEFAULT_PHONE_NUMBER = '0000000';
export const NUM_CARS_PER_PAGE = '12';
export const SITE_TITLE = process.env.GATSBY_COMPANY_NAME;
export const SITE_URL = (process.env.GATSBY_COMPANY_URL || '').replace(
  /\/$/,
  ''
);

const linkToCarViewingRoute = '/cars/';
function getMakeUrl(makeId: string | number) {
  return linkToCarViewingRoute + makeId;
}
function getModelUrl(makeId: string | number, modelId: string | number) {
  return getMakeUrl(makeId) + '/' + modelId;
}
export const ROUTER_LINKS = {
  home: '/',
  login: API_URL + '/auth/login?redirectUrl=',
  logout: API_URL + '/auth/logout',
  goLogin() {
    return `${API_URL}/auth/login?redirectUrl=${
      typeof window !== 'undefined' ? window.location.href : ''
    }`;
  },
  goSignUp() {
    return `${API_URL}/auth/sign-up?redirectUrl=${
      typeof window !== 'undefined' ? window.location.href : ''
    }`;
  },
  auctionSell: '/sell/',
  adminForCars: '/admin/cars/',
  globalAdminPanel: process.env.GATSBY_ADMIN_PANEL_URL || '/admin/cars/',
  car: {
    edit: '/account/cars/',
    view: linkToCarViewingRoute,
    sell: '/account/sell-car/',
    getMakeUrl,
    getModelUrl,
    getDetailPageUrl(
      makeId: string | number,
      modelId: string | number,
      carId: string
    ) {
      return getModelUrl(makeId, modelId) + '/' + carId;
    },
  },
  accountSettings: '/account/settings',
};

export const GA_EVENTS = {
  carDetailPage: {
    callSeller: {
      auth: 'classifieds_call_auth',
      unAuth: 'classifieds_call_not_auth',
    },
    contactSellerBtn: {
      auth: 'classifieds_contact_auth',
      unAuth: 'classifieds_contact_not_auth',
    },
    contactSellerSendForm: 'classifieds_contact_send',
  },
  sellMyCar: {
    link: {
      auth: 'classifieds_sell_click_auth',
      unAuth: 'classifieds_sell_click_unauth',
    },
    steps(stage: number, isEdit: boolean | null, type: 'page' | 'save' | 'continue') {
      let stageByKey: 'registration' | 'info' | 'description' | 'photos' =
        'registration';
      let edit = true;

      if (isEdit === null) {
        edit = window.location.pathname.includes('/cars/');
      } else {
        edit = isEdit;
      }

      switch (stage) {
        case 1:
          stageByKey = 'registration';
          break;
        case 2:
          stageByKey = 'info';
          break;
        case 3:
          stageByKey = 'description';
          break;
        case 4:
          stageByKey = 'photos';
          break;
      }

      const dictionary = {
        new: {
          registration: {
            page: 'classifieds_sell_new_step_1',
            save: 'classifieds_sell_new_step_1_save',
            continue: 'classifieds_sell_new_step_1_continue_later',
          },
          info: {
            page: 'classifieds_sell_new_step_2',
            save: 'classifieds_sell_new_step_2_save',
            continue: 'classifieds_sell_new_step_2_continue_later',
          },
          description: {
            page: 'classifieds_sell_new_step_3',
            save: 'classifieds_sell_new_step_3_save',
            continue: 'classifieds_sell_new_step_3_continue_later',
          },
          photos: {
            page: 'classifieds_sell_new_step_4',
            save: 'classifieds_sell_new_step_4_save',
            continue: 'classifieds_sell_new_step_4_continue_later',
          },
        },
        edit: {
          registration: {
            page: 'classifieds_sell_edit_step_1',
            save: 'classifieds_sell_edit_step_1_save',
            continue: 'classifieds_sell_edit_step_1_continue_later',
          },
          info: {
            page: 'classifieds_sell_edit_step_2',
            save: 'classifieds_sell_edit_step_2_save',
            continue: 'classifieds_sell_edit_step_2_continue_later',
          },
          description: {
            page: 'classifieds_sell_edit_step_3',
            save: 'classifieds_sell_edit_step_3_save',
            continue: 'classifieds_sell_edit_step_3_continue_later',
          },
          photos: {
            page: 'classifieds_sell_edit_step_4',
            save: 'classifieds_sell_edit_step_4_save',
            continue: 'classifieds_sell_edit_step_4_continue_later',
          },
        },
      };

      return dictionary[edit ? 'edit' : 'new'][stageByKey][type];
    },
  },
  updateStatus: {
    moderation: 'classifieds_sell_moderation',
    archive: 'classifieds_sell_archive',
  },
  updateUser: 'account_info_save',
  auth: {
    login: 'account_login_click',
    signUp: 'account_signup_click',
  },
};
