import { PageNavigationDto } from '@/shared/api';

export enum VIEWER_ROLE {
  CLIENT = 'client',
  ADMIN = 'admin',
}

export interface Viewer {
  id: string;
  email: string;
  forename: string | null;
  surname: string | null;
  addressLineOne: string | null;
  addressLineTwo: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  phoneNumber: string | null;
  createdAt: string;
  updatedAt: string;
  username: string;
  role: VIEWER_ROLE;
}

export type UpdateViewerDto = Partial<
  Omit<Viewer, 'id' | 'createdAt' | 'updatedAt' | 'role' | 'username'>
>;

export interface GetAllUserCarsDto extends PageNavigationDto {
  userId: string;
}

export interface SendEmailToUserDto {
  message: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  senderEmail: string;
}
