import {
  GetAllUserCarsDto,
  SendEmailToUserDto,
  UpdateViewerDto,
  Viewer,
} from './viewer.types';
import { API_URL } from '@/shared/config';
import { apiRequests, DataWithNavigation } from '@/shared/api';
import { ClassifiedCar } from '../sell-car';

export async function getViewer(): Promise<Viewer | null> {
  try {
    const response = await fetch(API_URL + '/user/current', {
      method: 'GET',
      credentials: 'include',
    });

    const responseBody = await response.json();

    if (response.ok && !('statusCode' in responseBody)) {
      return responseBody;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Request GetViewer failed:', error);

    return null;
  }
}

export async function sendEmail(
  userId: string,
  data: SendEmailToUserDto
): Promise<boolean> {
  return apiRequests.post.json<SendEmailToUserDto, boolean>(
    `/user/${userId}/email`,
    data,
    { credentials: 'include' },
    false,
    'Failed to send message',
    'Message sent successfully',
    true
  );
}

export async function updateViewer(
  userId: string,
  data: UpdateViewerDto
): Promise<boolean> {
  return apiRequests.patch<UpdateViewerDto, boolean>(
    `/user/${userId}`,
    data,
    { credentials: 'include' },
    false,
    'Failed to update user data',
    'User data has been successfully updated',
    true
  );
}

export async function getMyCars({
  userId,
  ...query
}: GetAllUserCarsDto): Promise<DataWithNavigation<'cars', ClassifiedCar[]>> {
  return apiRequests.get<DataWithNavigation<'cars', ClassifiedCar[]>>(
    `/user/${userId}/classified-car` +
      (Object.keys(query).length
        ? '/?' + new URLSearchParams(query as Record<string, string>)
        : ''),
    {
      page: 1,
      pageCount: 0,
      cars: [],
    },
    'Failed to get car data',
    { credentials: 'include' }
  );
}
