import * as React from 'react';
import { Viewer } from './viewer.types';
import { getViewer } from './viewer.api';
import { useLocalStorage } from '@lib/browser';
import { AUTH_KEY } from '@/shared/config';

type ViewerValue = Viewer | null;

export const ViewerContext = React.createContext<{
  viewer: ViewerValue;
  updateViewer: (value: ViewerValue) => void;
}>({
  viewer: null,
  updateViewer: (value: ViewerValue) => {},
});

export const ViewerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [viewer, setViewer] = React.useState<ViewerValue>(null);
  const updateViewer = (value: ViewerValue) => {
    setViewer(value);
  };

  React.useEffect(() => {
    if (!viewer) {
      const {
        value: isAuth,
        set: setAuthState,
        remove: removeAuthState,
      } = useLocalStorage<boolean>(AUTH_KEY, false);

      getViewer().then((response) => {
        if (response) {
          if (!isAuth) {
            setAuthState(true);
          }

          updateViewer(response);
        } else {
          removeAuthState();
        }
      });
    }
  }, []);

  return (
    <ViewerContext.Provider value={{ viewer, updateViewer }}>
      {children}
    </ViewerContext.Provider>
  );
};
