import { isBrowser } from './config';

interface UseLocalStorageResponse<T> {
  value: T;
  set: (value: T) => void;
  remove: () => void;
}

export function getLocaleStorage<T>(key: string, initialValue: T) {
  const valueLS = isBrowser ? window.localStorage.getItem(key) : null;
  let value: T;

  try {
    value = valueLS ? JSON.parse(valueLS) : initialValue;
  } catch {
    value = initialValue;
  }

  return value;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): UseLocalStorageResponse<T> {
  return {
    value: getLocaleStorage(key, initialValue),
    set: (value: T) => window.localStorage.setItem(key, JSON.stringify(value)),
    remove: () => window.localStorage.removeItem(key),
  };
}
