import { toast } from 'react-toastify';
import { API_URL } from '../config';
import { ApiError } from './api.types';
import { ToastOptions } from 'react-toastify/dist/types';

export const apiRequests = {
  get: getViaApi,
  post: {
    formData: postFormDataViaApi,
    json: postJSONViaApi,
  },
  patch: patchViaApi,
  delete: deleteViaApi,
  put: putViaApi,
};

async function getViaApi<R>(
  url: string,
  defaultResponse: R = null,
  errorText?: string,
  requestOptions?: RequestInit
): Promise<R> {
  try {
    const { success, result } = await requestHandler<R>(API_URL + url, {
      method: 'GET',
      ...requestOptions,
    });

    return result || defaultResponse;
  } catch (error) {
    errorHandler(error, errorText);

    return defaultResponse;
  }
}

async function postFormDataViaApi<D extends FormData, R>(
  url: string,
  data: D,
  requestOptions?: RequestInit,
  defaultResponse: R = null,
  errorText?: string,
  successText?: string
): Promise<R> {
  try {
    const { success, result } = await requestHandler<R>(
      API_URL + url,
      {
        method: 'POST',
        body: data,
        ...requestOptions,
      },
      errorText
    );

    if (success && successText) {
      toast.success(successText, { theme: 'colored' });
    }

    return result || defaultResponse;
  } catch (error) {
    errorHandler(error, errorText);

    return defaultResponse;
  }
}
async function postJSONViaApi<D, R>(
  url: string,
  data: D,
  requestOptions?: RequestInit,
  defaultResponse: R = null,
  errorText?: string,
  successText?: string,
  isEmpty?: boolean
): Promise<R> {
  try {
    const { success, result } = await requestHandler<R>(
      API_URL + url,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        ...requestOptions,
      },
      errorText,
      isEmpty
    );

    if (success && successText) {
      toast.success(successText, { theme: 'colored' });
    }

    return isEmpty ? (success as R) : result || defaultResponse;
  } catch (error) {
    errorHandler(error, errorText);

    return defaultResponse;
  }
}

async function deleteViaApi(
  url: string,
  errorText?: string,
  successText?: string,
  requestOptions?: RequestInit
): Promise<boolean> {
  try {
    let success = false;
    const response = await fetch(API_URL + url, {
      method: 'DELETE',
      ...requestOptions,
    });

    if (response.ok) {
      success = true;

      if (successText) {
        toast.success(successText, { theme: 'colored' });
      }
    }

    return success;
  } catch (error) {
    errorHandler(error, errorText);

    return false;
  }
}

async function patchViaApi<D, R>(
  url: string,
  data: D,
  requestOptions?: RequestInit,
  defaultResponse: R = null,
  errorText?: string,
  successText?: string,
  isEmpty?: boolean
): Promise<R> {
  try {
    const { success, result } = await requestHandler<R>(
      API_URL + url,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        ...requestOptions,
      },
      errorText,
      isEmpty
    );

    if (success && successText) {
      toast.success(successText, { theme: 'colored' });
    }

    return isEmpty ? (success as R) : result || defaultResponse;
  } catch (error) {
    errorHandler(error, errorText);

    return defaultResponse;
  }
}

async function putViaApi<D>(
  url: string,
  data: D,
  requestOptions?: RequestInit,
  errorText?: string,
  successText?: string
): Promise<boolean> {
  try {
    let success = false;
    const response = await fetch(API_URL + url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      ...requestOptions,
    });

    if (response.ok) {
      success = true;

      if (successText) {
        toast.success(successText, { theme: 'colored' });
      }
    } else if (errorText) {
      errorHandler(null, errorText);
    }

    return success;
  } catch (error) {
    errorHandler(error, errorText);

    return false;
  }
}

async function requestHandler<T>(
  input: RequestInfo,
  init?: RequestInit,
  errorText?: string,
  isEmpty?: boolean
): Promise<{ success: boolean; result: T | null }> {
  const response = await fetch(input, init);

  const responseBody = isEmpty ? null : await response.json();

  if (response.ok && (isEmpty || !('statusCode' in responseBody))) {
    return { success: true, result: responseBody };
  } else {
    errorHandler(responseBody, errorText);

    return { success: false, result: null };
  }
}

function errorHandler(error?: Error | ApiError | null, errorText?: string) {
  const toastOptions: ToastOptions = { theme: 'colored' };

  if (process.env.NODE_ENV === 'development') {
    console.error('Request failed:', error);
  }

  if (
    error &&
    'message' in error &&
    Array.isArray(error.message) &&
    error.message.length
  ) {
    toast.error(error.message.join('; '), toastOptions);
  } else {
    toast.error(errorText || 'Request failed', toastOptions);
  }
}
